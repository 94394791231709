import React, {useState} from 'react';

import LogZone from "./LogZone";

import config from "../config";  // 引入 config.js

function Left() {

    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [logInfo, setLogInfo] = useState({}); // 初始化为一个空对象

    // Handling file selection
    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    // Handling file upload
    const handleUpload = async () => {
        if (!file) {
            alert('Please select a file to upload.');
            return;
        }

        setUploading(true);
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await fetch(config.API_BASE_URL + '/upload', {
                method: 'POST', body: formData,
            });
            console.log(response)
            if (response.ok) {
                const result = await response.json();
                setLogInfo(result)
                // alert('File uploaded successfully: ' + result);
            } else {
                const error = await response.text();
                setErrorMessage('Upload failed: ' + error);
            }
        } catch (error) {
            setErrorMessage('Error uploading file: ' + error.message);
        } finally {
            setUploading(false);
        }
    };
    return (<>
        <div className="left-column">
            <input className="select-file"
                   type="file"
                   id="upload-btn"
                   disabled={uploading}
                   onChange={handleFileChange}/>
            <button
                className="confirm-btn"
                onClick={handleUpload}
                disabled={uploading}  // 上传中时禁用上传按钮
            >
                {uploading ? 'Uploading...' : '确认上传'}
            </button>
            <LogZone logInfo={logInfo}/>
        </div>
    </>);
}

export default Left;