// import logo from './logo.svg';
import './App.css';
import Left from "./components/Left";
import Right from "./components/Right";
import {StatusProvider} from "./context/StatusContext";

function App() {

    return (
        <StatusProvider>
            <div className="container">
                <Left/>
                <Right/>
            </div>
        </StatusProvider>
    );
}

export default App;
