// StatusContext.js
import React, { createContext, useState, useContext } from "react";

// 创建一个 Context
const StatusContext = createContext();

export const useStatus = () => {
    return useContext(StatusContext);  // 自定义 Hook 用来简化使用 context
};

// 创建一个 Context Provider 来管理状态
export const StatusProvider = ({ children }) => {
    const [status, setStatus] = useState({});  // 用来保存状态

    return (
        <StatusContext.Provider value={{ status, setStatus }}>
            {children}
        </StatusContext.Provider>
    );
};
