import React, {useEffect, useState} from "react";
import {useStatus} from "../context/StatusContext";
import config from "../config";  // 引入 Context

function Right() {
    const {status} = useStatus();  // 获取共享的 status
    const [logResult, setLogResult] = useState(null);  // 用来保存从后端获取的结果

    // 监听 status.status 是否为 "completed" 并调用后端接口
    useEffect(() => {
        if (status && status.status === "completed") {
            fetchThreadResult(status.thread_id);
        }
    }, [status]);  // 依赖 status，status 变化时会触发此 effect

    // 调用后端方法获取线程结果
    const fetchThreadResult = async (threadId) => {
        try {
            const response = await fetch(config.API_BASE_URL + `/result?thread_id=${threadId}`, {
                method: 'GET',
            });

            if (response.ok) {
                const result = await response.text();
                console.log("Thread result:", result)
                setLogResult(result);  // 设置返回的结果
            } else {
                console.error("Error fetching thread result:", response.statusText);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <div className="right-column">
            <h3>任务状态</h3>
            <p>Status: {status ? status.status + ", ID: " : "等待中..."}{status ? status.thread_id : ""}</p>
            {logResult ? (
                <div>
                    <h4>任务结果</h4>
                    <pre>{logResult}</pre>
                </div>
            ) : null}
        </div>
    );
}

export default Right;
