import React, {useState, useEffect} from "react";
import {useStatus} from "../context/StatusContext";
import config from "../config";  // 引入 Context}

function LogZone({logInfo}) {
    const {setStatus} = useStatus();  // 获取 setStatus 方法
    const [logs, setLogs] = useState(null);
    const [runId, setRunId] = useState(null);   // 用来保存 run_id
    const [threadId, setThreadId] = useState(null); // 用来保存 thread_id

    // 在组件加载时，发送 POST 请求并开始轮询
    useEffect(() => {
        if (logInfo) {
            postLogInfo(logInfo);
        }
    }, [logInfo]); // 当 logInfo 改变时重新执行

    // 将 logInfo 发送到后端并获取 run_id 和 thread_id
    const postLogInfo = async (logInfo) => {
        try {
            const response = await fetch(config.API_BASE_URL +'/log', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(logInfo),  // 将 logInfo 转换为 JSON 字符串发送
            });

            if (response.ok) {
                const result = await response.json();
                setLogs(result); // 设置返回的日志数据
                setRunId(result.run_id);  // 保存 run_id
                setThreadId(result.thread_id); // 保存 thread_id
                startPolling(logInfo.run_id, logInfo.thread_id);  // 开始轮询
            } else {
                console.error("Error sending log info:", response.statusText);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    // 启动轮询
    const startPolling = (run_id, thread_id) => {
        const intervalId = setInterval(async () => {
            const response = await fetch(config.API_BASE_URL +'/log', {
                method: 'POST',  // 使用 POST 请求
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({"run_id": run_id, "thread_id": thread_id})
            });
            const result = await response.json();

            // 如果日志的状态不为 'completed', 'failed', 'exception' 则继续轮询
            if (result.status !== 'completed' && result.status !== 'failed' && result.status !== 'exception') {
                let data = {"status": result.status, "run_id": run_id, "thread_id": thread_id}
                setStatus(data);  // 更新 status
                setLogs(prevLogs => ({
                    ...prevLogs,
                    msg: prevLogs.msg + '\n' + result.msg  // 持续追加新的日志消息
                }));
            } else {
                let data = {"status": result.status, "run_id": run_id, "thread_id": thread_id}
                setStatus(data);
                setLogs(prevLogs => ({
                    ...prevLogs,
                    msg: prevLogs.msg + '\n' + result.msg  // 持续追加新的日志消息
                }));
                clearInterval(intervalId);  // 结束轮询
            }
        }, 5000);  // 每 5 秒轮询一次
    };

    return (
        <div id="log-output">
            <h3>日志输出区</h3>
            {logs ? (
                <pre>{logs.msg}</pre>
            ) : (
                <p>等待日志数据...</p>
            )}
        </div>
    );
}

export default LogZone;
